var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('main',[_c('div',{staticClass:"container-fluid px-4"},[_c('div',{staticClass:"card mb-4 mt-4"},[_c('div',{staticClass:"card-header bg-primary text-bg-dark"},[_vm._v("Permohonan KTM")]),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row mb-3"},[_c('div',{staticClass:"col-md-3 col-6"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.filter.status),expression:"filter.status"}],staticClass:"form-select",attrs:{"name":"status"},on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.filter, "status", $event.target.multiple ? $$selectedVal : $$selectedVal[0])},function($event){return _vm.fetchData()}]}},[_c('option',{attrs:{"value":"0"}},[_vm._v("Pengajuan")]),_c('option',{attrs:{"value":"1"}},[_vm._v("Selesai")])])])]),_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.rows,"lineNumbers":true,"search-options":{
              enabled: true,
              placeholder: 'Cari',
            },"pagination-options":{
              enabled: true,
              mode: 'records',
              perPage: 9,
              perPageDropdownEnabled: false,
              position: 'bottom',
              nextLabel: '',
              prevLabel: '',
              ofLabel: 'of',
              pageLabel: 'page',
              allLabel: 'All',
            }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'tanggal')?_c('span',[_vm._v(" "+_vm._s(_vm.formatTanggal(props.row.created_at))+" ")]):_vm._e(),(props.column.field == 'status')?_c('span',[(props.row.status_permohonan == 0)?_c('span',{staticClass:"badge text-bg-primary"},[_vm._v("Pengajuan")]):_vm._e(),(props.row.status_permohonan == 1)?_c('span',{staticClass:"badge text-bg-success"},[_vm._v("Selesai")]):_vm._e()]):_vm._e(),(props.column.field == 'action')?_c('span',[_c('div',[_c('router-link',{staticClass:"btn btn-sm btn-success",staticStyle:{"margin-right":"5px"},attrs:{"to":'/permohonan-ktm/print/' + props.row.id}},[_c('font-awesome-icon',{attrs:{"icon":"print"}})],1),(props.row.status_permohonan == 0)?_c('button',{staticClass:"btn btn-sm btn-info",staticStyle:{"margin-right":"5px"},on:{"click":function($event){return _vm.ceklistData(props.row.id)}}},[_c('font-awesome-icon',{attrs:{"icon":"check"}})],1):_vm._e(),(props.row.status_permohonan == 1)?_c('button',{staticClass:"btn btn-sm btn-warning",staticStyle:{"margin-right":"5px"},on:{"click":function($event){return _vm.unceklistData(props.row.id)}}},[_c('font-awesome-icon',{attrs:{"icon":"times"}})],1):_vm._e(),_c('button',{staticClass:"btn btn-sm btn-danger",on:{"click":function($event){return _vm.confirmDelete(props.row.id)}}},[_c('font-awesome-icon',{attrs:{"icon":"trash-alt"}})],1)],1)]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])},[_c('div',{staticClass:"text-center",attrs:{"slot":"emptystate"},slot:"emptystate"},[_vm._v(" "+_vm._s(_vm.tableInfo)+" ")]),_c('div',{attrs:{"slot":"table-actions"},slot:"table-actions"},[_c('div',{staticStyle:{"display":"flex"}},[_c('button',{staticClass:"btn btn-sm btn-secondary",staticStyle:{"margin-right":"5px"},attrs:{"type":"button"},on:{"click":function($event){return _vm.fetchData()}}},[_c('font-awesome-icon',{attrs:{"icon":"sync","spin":_vm.loading}})],1)])])])],1)])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }