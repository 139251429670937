<template>
  <div style="background-color: aliceblue">
    <Header></Header>
    <main>
      <div class="p-4">
        <div
          class="row align-items-center justify-content-center"
          style="margin-top: -180px"
        >
          <div class="col-md-6 bg-white p-4 rounded-5 shadow-lg box">
            <h4 class="text-center">Informasi Mahasiswa</h4>
            <hr />
            <div class="row">
              <div class="col-md-3 mb-2">
                <img
                  :src="detail.img"
                  style="width: 100%; border-radius: 10px"
                />
              </div>
              <div class="col-md-9">
                <div class="row">
                  <div class="col-md-12">
                    <label>Nama :</label>
                    <h5>{{ detail.nama_lengkap }}</h5>
                  </div>
                  <div class="col-md-12">
                    <label>NIM :</label>
                    <h5>{{ detail.nim }}</h5>
                  </div>
                  <div class="col-md-12">
                    <label>Tempat. Tanggal Lahir :</label>
                    <h5>
                      {{ detail.tempat_lahir }},
                      {{ this.indonesianFormat(detail.tgl_lahir) }}
                    </h5>
                  </div>
                  <div class="col-md-12">
                    <label>Program Studi :</label>
                    <h5>{{ detail.program_studi }}</h5>
                  </div>
                  <div class="col-md-12">
                    <label>Angkatan :</label>
                    <h5>{{ detail.angkatan }}</h5>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <hr />
                <span
                  >Mahasiswa dengan data di atas adalah benar terdaftar sebagai
                  Mahasiswa di STMIK Adhi Guna. Berlaku sampai dengan tahun
                  {{ parseInt(detail.angkatan) + 5 }}</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "./Header.vue";
import Footer from "./Footer.vue";
import axios from "axios";
import moment from "moment";

export default {
  name: "HomePage",
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      detail: {
        nama_lengkap: "",
        nim: "",
        no_handphone: "",
        program_studi: "",
        tempat_lahir: "",
        tgl_lahir: "",
        angkatan: "",
        img: "",
      },
    };
  },
  created() {
    moment.updateLocale("en", {
      months: [
        "Januari",
        "Februari",
        "Maret",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Agustus",
        "September",
        "Oktober",
        "November",
        "Desember",
      ],
    });
  },
  mounted() {
    const id = this.$route.params.id;
    this.fetchData(id);
  },
  methods: {
    fetchData(param) {
      axios
        .get("/v1/permohonan_ktm/nim/" + param)
        .then((response) => {
          for (let key in response.data.data) {
            this.detail[key] = response.data.data[key];
          }
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    indonesianFormat(dateTime) {
      return moment(dateTime).format("D MMMM YYYY");
    },
  },
};
</script>

<style scoped>
@font-face {
  font-family: "Saira";
  src: url("@/assets/saira.ttf") format("truetype");
}
.no-link {
  text-decoration: none;
}
.box {
  border-top: solid 5px #2968a0;
  border-bottom: solid 5px #0099ff;
}
.skeleton-loader {
  width: 100%;
  height: 20px;
  display: block;
  border-radius: 3px;
  background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0.5) 50%,
      rgba(255, 255, 255, 0) 80%
    ),
    #d3d3d3;
  background-repeat: repeat-y;
  background-size: 50px 500px;
  background-position: 0 0;
  animation: shine 1s infinite;
}
@keyframes shine {
  to {
    background-position: 100% 0, /* move highlight to right */ 0 0;
  }
}

.image-groups {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
  border: 1px solid #ccc;
  margin-bottom: 5px;
}

/* Image styling */
.image-container {
  display: flex;
  align-items: center;
  width: 30px;
  height: 30px;
}

.image-item {
  min-width: 30px;
  max-width: 30px;
  min-height: 30px;
  max-height: 30px;
  object-fit: cover;
  margin-right: 5px;
}

.tombol-hapus {
  float: right;
  color: rgb(255, 0, 0);
  border-radius: 3px;
  border: none;
  height: 30px;
  width: 30px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8);
}
</style>
