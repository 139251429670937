<template>
  <div id="layoutSidenav_nav">
    <nav
      class="sb-sidenav accordion sb-sidenav-dark"
      id="sidenavAccordion"
      style="box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2)"
    >
      <div class="sb-sidenav-menu">
        <div class="nav">
          <div class="sb-sidenav-menu-heading">Menu</div>
          <router-link
            class="nav-link"
            to="/dashboard"
            :class="{
              ' active bg-active': link == 'dashboard',
            }"
          >
            <div class="sb-nav-link-icon">
              <font-awesome-icon icon="tachometer-alt" />
            </div>
            <span>Dashboard</span>
          </router-link>
          <router-link
            v-if="userLevel == 'admin'"
            class="nav-link"
            to="/permohonan-ktm"
            :class="{
              ' active bg-active': link == 'permohonan-ktm',
            }"
          >
            <div class="sb-nav-link-icon">
              <font-awesome-icon icon="users" />
            </div>
            <span>Permohonan KTM</span>
          </router-link>
          <router-link
            v-if="userLevel == 'admin'"
            class="nav-link"
            to="/pengguna"
            :class="{
              ' active  bg-active': link == 'pengguna',
            }"
          >
            <div class="sb-nav-link-icon">
              <font-awesome-icon icon="file-alt" />
            </div>
            <span>Pengguna</span>
          </router-link>
        </div>
      </div>
    </nav>
  </div>
</template>
<script>
export default {
  name: "SideBar",
  data() {
    return {
      link: "dashboard",
      isMasterMenuOpen: false,
      fullName: "User",
      userName: "User",
      userLevel: "user",
    };
  },
  created() {
    const user = localStorage.getItem("userData");
    if (user) {
      this.user = JSON.parse(user);
      this.fullName = this.user.nama;
      this.userName = this.user.username;
      this.userLevel = this.user.level;
    }
  },
  watch: {
    "$route.path": {
      immediate: true,
      handler(newValue) {
        const parts = newValue.split("/");
        const link = parts[1];
        this.link = link;
        this.isMasterMenuOpen =
          this.link === "dosen" ||
          this.link === "ruang" ||
          this.link === "kelas" ||
          this.link === "mata-kuliah";
      },
    },
  },
};
</script>
<style scoped>
.nav-link {
  border-radius: 10px;
  margin: 2px 5px;
}
.bg-active {
  background-color: #0093dd;
  transition: width 0.5s ease-in-out, background-color 0.5s linear;
}
</style>

