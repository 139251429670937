<template>
  <div>
    <main>
      <div class="container-fluid px-4">
        <form
          @submit.prevent="postData"
          id="formPeserta"
          enctype="multipart/form-data"
        >
          <div class="card mb-4 mt-4">
            <div class="card-header bg-primary text-light">Form Pengguna</div>
            <div class="card-body">
              <div class="row">
                <div class="col-md-4 mt-2">
                  <div class="form-group">
                    <label>Nama</label>
                    <input
                      type="text"
                      class="form-control"
                      :class="formValidate.full_name ? ' is-invalid' : ''"
                      name="full_name"
                      v-model="form.full_name"
                    />
                    <div
                      class="invalid-feedback"
                      v-html="formValidate.full_name"
                    ></div>
                  </div>
                </div>
                <div class="col-md-4 mt-2">
                  <div class="form-group">
                    <label>Telp/WA</label>
                    <input
                      type="text"
                      class="form-control"
                      :class="formValidate.phone_number ? ' is-invalid' : ''"
                      name="phone_number"
                      v-model="form.phone_number"
                    />
                    <div
                      class="invalid-feedback"
                      v-html="formValidate.phone_number"
                    ></div>
                  </div>
                </div>
                <div class="col-md-4 mt-2">
                  <div class="form-group">
                    <label>Username</label>
                    <input
                      type="text"
                      class="form-control"
                      :class="formValidate.username ? ' is-invalid' : ''"
                      name="username"
                      v-model="form.username"
                    />
                    <div
                      class="invalid-feedback"
                      v-html="formValidate.username"
                    ></div>
                  </div>
                </div>
                <div class="col-md-4 mt-2" v-if="userLevel == 'admin'">
                  <div class="form-group">
                    <label>Level User</label>
                    <select
                      name="level"
                      class="form-select"
                      :class="formValidate.level ? ' is-invalid' : ''"
                      v-model="form.level"
                    >
                      <option value="admin">Admin</option>
                    </select>
                    <div
                      class="invalid-feedback"
                      v-html="formValidate.level"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-footer">
              <button
                class="btn btn-sm btn-success float-end"
                @click="postData()"
                :disabled="this.loading"
              >
                Simpan
              </button>
            </div>
          </div>
        </form>
      </div>
    </main>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";

export default {
  name: "IndexPage",
  data() {
    return {
      form: {
        id_users: "",
        full_name: "",
        phone_number: "",
        username: "",
        old_username: "",
        level: "",
      },
      formValidate: [],
      loading: false,
      userLevel: "user",
    };
  },
  created() {
    const user = localStorage.getItem("userData");
    if (user) {
      this.user = JSON.parse(user);
      this.userLevel = this.user.level;
    }
  },
  mounted() {
    const id = this.$route.params.id;
    this.nomor_identitas = id;
    this.fetchData();
  },
  methods: {
    postData() {
      this.loading = true;
      const formData = new FormData();
      formData.append("id_users", this.form.id_users);
      formData.append("full_name", this.form.full_name);
      formData.append("phone_number", this.form.phone_number);
      formData.append("old_username", this.form.old_username);
      formData.append("username", this.form.username);
      formData.append("level", this.form.level);

      axios
        .post("/v1/users/update/", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          if (response.data.validasi == false) {
            this.loading = false;
            this.formValidate = response.data.message;
          } else {
            this.loading = false;
            Swal.fire({
              title: response.data.title,
              text: response.data.message,
              icon: response.data.icon,
              showCancelButton: false,
              confirmButtonColor: "#3085d6",
              confirmButtonText: "OK!",
            }).then(() => {
              //   this.$router.push("/pengguna");
              this.$router.go(-1);
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    fetchData() {
      axios
        .get("/v1/users/detail/" + this.nomor_identitas)
        .then((response) => {
          this.rows = response.data.data;
          this.form.id_users = response.data.data.id_users;
          this.form.full_name = response.data.data.full_name;
          this.form.phone_number = response.data.data.phone_number;
          this.form.username = response.data.data.username;
          this.form.old_username = response.data.data.username;
          this.form.level = response.data.data.level;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
  },
};
</script>