<template>
  <div id="layoutAuthentication">
    <div id="layoutAuthentication_content">
      <main class="p-3">
        <button @click="goPrint()" class="btn btn-float-bottom btn-info">
          <font-awesome-icon icon="print" />
        </button>
        <button @click="goBack()" class="btn btn-float-top btn-primary">
          <font-awesome-icon icon="arrow-left" />
        </button>
        <div
          class="row"
          style="border-bottom: solid 1px #000; padding-bottom: 25px"
        >
          <div
            class="col-6 text-right d-flex justify-content-end"
            style="border-right: solid 1px #000"
          >
            <img :src="detail.qrcode" alt="" class="qr-code" />
            <img :src="detail.ktm" alt="" class="ktm" />
          </div>
          <div class="col-6 d-flex justify-content-start">
            <img
              v-if="detail.program_studi == 'Sistem Informasi'"
              src="@/assets/back-blanko-si.png"
              alt=""
              class="ktm"
            />
            <img v-else src="@/assets/back-blanko-ti.png" alt="" class="ktm" />
          </div>
        </div>
      </main>
    </div>
  </div>
</template>
<style scoped>
.btn-float-bottom {
  width: 50px;
  height: 50px;
  line-height: 25px;
  text-align: center;
  color: white;
  cursor: pointer;
  font-size: 16px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  position: fixed;
  right: 30px;
  bottom: 30px;
}

.btn-float-top {
  width: 50px;
  height: 50px;
  line-height: 25px;
  text-align: center;
  color: white;
  cursor: pointer;
  font-size: 16px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  position: fixed;
  right: 30px;
  bottom: 90px;
}
.ktm {
  width: 8.8cm;
}

.qr-code {
  position: relative;
  width: 1.4cm;
  height: 1.4cm;
  top: 145px;
  left: 313px;
}

@media print {
  .btn {
    display: none;
  }
  .table {
    width: 100%;
  }
}
</style>
<script>
import axios from "axios";

export default {
  name: "PrintPage",
  data() {
    return {
      detail: {
        id: "",
        ktm: "",
        img: "",
        angkatan: "",
        qrcode: "",
        program_studi: "",
      },
      loading: false,
      message: "",
    };
  },
  mounted() {
    const id = this.$route.params.id;

    this.fetchData(id);
  },
  methods: {
    fetchData(param) {
      axios
        .get("/v1/permohonan_ktm/detail/" + param)
        .then((response) => {
          this.detail.ktm = response.data.data.ktm;
          this.detail.img = response.data.data.img;
          this.detail.qrcode = response.data.data.qrcode;
          this.detail.angkatan = response.data.data.angkatan;
          this.detail.program_studi = response.data.data.program_studi;
          //   this.drawCanvas();
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },

    // drawCanvas() {
    //   const canvas = this.$refs.myCanvas;
    //   const context = canvas.getContext("2d");
    //   const dpi = window.devicePixelRatio || 1;

    //   canvas.width = 2510 * dpi;
    //   canvas.height = 1476 * dpi;

    //   context.imageSmoothingQuality = "high";

    //   context.fillStyle = "#f0f0f0";
    //   context.fillRect(0, 0, canvas.width, canvas.height);

    //   const blanko = new Image();
    //   blanko.src = this.detail.ktm;

    //   blanko.onload = () => {
    //     context.drawImage(blanko, 0, 0, canvas.width, canvas.height);

    //     const sairaFont = new FontFace(
    //       "Saira",
    //       `url(${require("@/assets/saira.ttf")})`
    //     );

    //     sairaFont.load().then((font) => {
    //       document.fonts.add(font);

    //       const foto = new Image();
    //       foto.src = this.detail.qrcode;

    //       foto.onload = () => {
    //         context.clip("nonzero");

    //         context.drawImage(foto, 150 * dpi, 570 * dpi, 500 * dpi, 650 * dpi);

    //         const canvasImage = this.$refs.canvasImage;

    //         const dataURL = canvas.toDataURL("image/png");

    //         canvasImage.src = dataURL;
    //       };
    //     });
    //   };
    // },
    goBack() {
      this.$router.go(-1);
    },
    goPrint() {
      window.print();
    },
    printTable() {
      // Memanggil fungsi window.print() untuk mencetak
      window.print();
    },
  },
};
</script>