import Vue from "vue";
import VueRouter from "vue-router";

import axios from "axios";
import Swal from "sweetalert2";

Vue.use(VueRouter);

import Dashboard from "../pages/Dashboard.vue";
import Login from "../pages/Login.vue";

import Home from "../pages/landing-page/Home.vue";
import Nim from "../pages/landing-page/Nim.vue";
import Formulir1 from "../pages/landing-page/Formulir1.vue";

import PermohonanKtm from "../pages/permohonan-ktm/List.vue";
import PermohonanKtmPrint from "../pages/permohonan-ktm/Print.vue";

import Pengguna from "../pages/pengguna/List.vue";
import PenggunaForm from "../pages/pengguna/Form.vue";
import PenggunaEdit from "../pages/pengguna/Edit.vue";
import PenggunaPassword from "../pages/pengguna/Password.vue";

const routes = [{
    path: "/",
    component: Home
  },
  {
    path: "*",
    component: Home
  },
  {
    path: "/home",
    component: Home
  },
  {
    path: "/nim/:id",
    component: Nim
  },
  {
    path: "/formulir-1/",
    component: Formulir1
  },
  {
    path: "/dashboard/",
    component: Dashboard
  },
  {
    path: "/login/",
    component: Login
  },

  {
    path: "/permohonan-ktm/",
    component: PermohonanKtm
  },
  {
    path: "/permohonan-ktm/print/:id",
    component: PermohonanKtmPrint
  },

  {
    path: "/pengguna/",
    component: Pengguna,
    meta: {
      requiredLevel: "admin"
    }
  },
  {
    path: "/pengguna/create",
    component: PenggunaForm,
    meta: {
      requiredLevel: "admin"
    },
  },
  {
    path: "/pengguna/update/:id",
    component: PenggunaEdit
  },
  {
    path: "/pengguna/password/:id",
    component: PenggunaPassword
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

router.beforeEach((to, from, next) => {
  const isLoggedIn = localStorage.getItem("userData");
  const userAceess = JSON.parse(isLoggedIn);

  const userLevel = userAceess ? userAceess.level : null;
  const requiredLevel = to.meta.requiredLevel;

  if (
    !isLoggedIn &&
    to.path !== "/login" &&
    to.path !== "/home" &&
    to.path !== "/" &&
    to.path !== "/formulir-1" && to.path !== "/nim/" + to.params.id
  ) {
    next("/");
  } else if (to.path === "/login" && isLoggedIn) {
    next("/dashboard");
  } else if (requiredLevel && userLevel && userLevel !== requiredLevel) {
    if (!requiredLevel.includes(userLevel)) {
      next("/forbidden"); // Tambahkan halaman forbidden jika level pengguna tidak sesuai
    } else {
      next();
    }
  } else {
    next();
  }

  const userData = localStorage.getItem("userData");
  if (userData) {
    const user = JSON.parse(userData);

    axios
      .get("/v1/auth/status/", {
        headers: {
          token: user.token,
        },
      })
      .then((response) => {
        if (response.data.status === false) {
          Swal.fire({
            title: "Info",
            text: "Session Habis, Silahkan Login Kembali",
            icon: "info",
            showCancelButton: false,
            showConfirmButton: false,
          });
          setTimeout(() => {
            Swal.close();
            localStorage.removeItem("userData");
            if (to.path !== "/") {
              next("/");
            }
          }, 3000);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
});

export default router;