<template>
  <div class="footer">
    <div class="container">
      <div class="row">
        <div class="col-md-6 p-4">
          <h5>SIPANDU</h5>
          <span>STMIK Adhi Guna</span>
        </div>
        <div class="col-md-6 p-4">
          <h5>Tentang</h5>
          <span>
            Aplikasi Pelayanan Terpadu (SIPANDU) merupakan aplikasi satu pintu
            yang bisa di akses oleh seluruh mahasiswa STMIK Adhi Guna. Aplikasi
            pelayanan ini disediakan untuk melakukan permohonan berbagai
            keperluan akademik cara online / daring.
          </span>
        </div>
      </div>
    </div>
    <div class="copy">Copyright &copy; 2023 by MI | STMIK Adhi Guna Palu.</div>
  </div>
</template>
<script>
export default {
  name: "FooterPage",
};
</script>
<style scoped>
.footer {
  background-color: #2b2b2b;
  color: #fff;
}
.copy {
  border-top: solid 0.15em #fff;
  text-align: center;
  display: flex;
  align-items: center; /* Mengatur teks secara vertikal di tengah */
  justify-content: center; /* Mengatur teks secara horizontal di tengah */
  font-weight: light;
  font-size: 14px;
  padding: 20px;
  margin: 0 3em;
}
</style>