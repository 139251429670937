<template>
  <div>
    <nav
      class="navbar sticky-top navbar-expand-lg py-md-3 px-md-3 bg-white"
      style="box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2)"
    >
      <div class="container-md justify-content-md-centers">
        <img
          src="../../assets/logo-text.png"
          alt="Logo"
          height="50"
          class="d-inline-block align-text-top"
        />
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNavDropdown"
          aria-controls="navbarNavDropdown"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <i class="fas fa-bars"></i>
        </button>
        <div class="collapse navbar-collapse" id="navbarNavDropdown">
          <ul
            class="navbar-nav ms-auto d-flex justify-content-around mb-2 mb-lg-0"
          >
            <li class="nav-item flex-fill">
              <router-link
                class="nav-link"
                :class="{
                  ' active': link == '/',
                }"
                to="/"
              >
                Home
              </router-link>
            </li>
            <li class="nav-item flex-fill">
              <router-link class="nav-link" to="/login"> Login </router-link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
    <div class="header align-items-center justify-content-center"></div>
  </div>
</template>
<script>
export default {
  name: "HeaderPage",

  data() {
    return {
      link: "home",
      subtitle: "",
    };
  },
  watch: {
    "$route.path": {
      immediate: true,
      handler(newValue) {
        const parts = newValue.split("/");
        const link = parts[1];
        if (link == "formulir-1") {
          this.subtitle = "Pembuatan KTM ( Kartu Tanda Mahasiswa )";
        }
      },
    },
  },
};
</script>
<style scoped>
.nav-link {
  font-size: 1.15em;
  font-weight: 400;
  color: #0099ff;
  padding: 10px 5px;
}
.nav-link:hover {
  border-bottom: solid 2px #0099ff;
  color: #2968a0;
  font-weight: 600;
}
.header {
  margin-bottom: 200px;
}
</style>