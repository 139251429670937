<template>
  <div style="background-color: aliceblue">
    <Header></Header>
    <div class="p-4">
      <div
        class="row align-items-center justify-content-center"
        style="margin-top: -180px"
      >
        <div class="col-md-8 bg-white p-4 rounded-5 shadow-lg box">
          <h4 class="mb-4 text-center">Pilih Formulir Permohonan</h4>
          <div class="row">
            <div class="col-md-12 col-lg-6" style="margin-bottom: -30px">
              <a href="javascript:;" class="cards no-link" @click="openForm(1)">
                <div class="card-down bg-primary"></div>
                <div class="card-up">
                  <div class="card-content">
                    <p>Formulir 1</p>
                    <span>Pembuatan KTM ( Kartu Tanda Mahasiswa )</span>
                  </div>
                </div>
              </a>
            </div>
            <div class="col-md-6" style="margin-bottom: -30px">
              <a href="javascript:;" class="cards no-link">
                <div class="card-down bg-primary"></div>
                <div class="card-up">
                  <div class="card-content">
                    <p>Formulir 2</p>
                    <span>Surat Keterangan Aktif Kuliah (Soon)</span>
                  </div>
                </div>
              </a>
            </div>
            <div class="col-md-6" style="margin-bottom: -30px">
              <a href="javascript:;" class="cards no-link">
                <div class="card-down bg-primary"></div>
                <div class="card-up">
                  <div class="card-content">
                    <p>Formulir 3</p>
                    <span>Permohonan Cuti Akademik (Soon)</span>
                  </div>
                </div>
              </a>
            </div>
            <div class="col-md-6" style="margin-bottom: -30px">
              <a href="javascript:;" class="cards no-link">
                <div class="card-down bg-primary"></div>
                <div class="card-up">
                  <div class="card-content">
                    <p>Formulir 4</p>
                    <span>Surat Pengantar Penelitian (Soon)</span>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "./Header.vue";
import Footer from "./Footer.vue";

export default {
  name: "HomePage",
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      nim: 5720115114,
    };
  },
  methods: {
    openForm(param) {
      // Membuat objek untuk memetakan param ke route
      const routeMap = {
        1: "formulir-1",
        2: "formulir-2",
        3: "formulir-3",
        4: "formulir-4",
      };

      if (routeMap[param]) {
        this.$router.push(routeMap[param]);
      }
    },
  },
};
</script>

<style scoped>
.no-link {
  text-decoration: none;
}
.box {
  border-top: solid 5px #2968a0;
  border-bottom: solid 5px #0099ff;
}
.cards {
  padding: 10px;
}

.card-up {
  height: 120px;
  border-radius: 20px;
  background-color: #f0f8ff;
  color: #000;
  margin-left: 20px;
  margin-top: -108px;
  padding: 30px;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
  align-content: center;
  align-items: center;
}
.card-up:hover {
  background-color: #0099ff;
  color: #fff;
  transform: translateY(-10%);
  transition: transform 0.3s, opacity 0.3s, filter 0.3s;
}
.card-up p {
  font-size: 1.15em;
  font-weight: bold;
  margin-bottom: 2px;
}
.card-up span {
  font-size: 1em;
}
.card-down {
  height: 120px;
  border-radius: 20px;
  background-color: #2968a0;
  margin: 0px 20px 0px 0px;
  padding: 30px;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
  align-content: center;
  align-items: center;
}
</style>
