<template>
  <div>
    <main>
      <div class="container-fluid px-4">
        <h5 class="mt-4 mb-3">Dashboard</h5>
        <div class="alert alert-info" role="alert">
          <div class="row">
            <div class="col-md-7 p-4">
              <h2>
                Selamat Datang, <strong>{{ this.fullName }}</strong> !
              </h2>
              <p class="fs-6 mt-4">
                Selamat datang di Laman Aplikasi Pelayanan Terpadu (SIPANDU),
                Sekolah Tinggi Manajemen Informatika dan Komputer (STMIK) Adhi
                Guna Palu.
              </p>
            </div>
            <div class="col-md-5 d-none d-sm-block">
              <img
                src="../assets/admin_header.png"
                style="height: 200px; float: right"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "DashboardPage",
  data() {
    return {
      detail: {
        bangunan: "",
        saluran: "",
        jalan: "",
        galian: "",
      },
      loading: true,
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      axios
        .get("/v1/dashboard")
        .then((response) => {
          for (let key in this.detail) {
            this.detail[key] = response.data[key];
          }
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
  },
};
</script>
<style scoped>
@font-face {
  font-family: "IrigationIcon";
  src: url("../assets/fonts/icomoon.woff") format("woff");
  /* tambahkan format lain jika diperlukan */
}
.card-body {
  height: 90px;
}

.card-body span {
  font-size: 20px;
  font-weight: bold;
}

.card-body i {
  font-size: 50px;
  float: right;
  margin-top: -35px;
}

.icon-saluran:before {
  font-family: "IrigationIcon";
  content: "\e900";
  font-style: normal;
}
.icon-bendungan:before {
  font-family: "IrigationIcon";
  content: "\e90e";
  font-style: normal;
}
.icon-galian:before {
  font-family: "IrigationIcon";
  content: "\e913";
  font-style: normal;
}
.icon-jalan:before {
  font-family: "IrigationIcon";
  content: "\e91e";
  font-style: normal;
}
.stretched-link {
  text-decoration: none;
}
</style>

