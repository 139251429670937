<template>
  <div>
    <main>
      <div class="container-fluid px-4">
        <form
          @submit.prevent="postData"
          id="formPeserta"
          enctype="multipart/form-data"
        >
          <div class="card mb-4 mt-4">
            <div class="card-header bg-primary text-light">Form Pengguna</div>
            <div class="card-body">
              <div class="row">
                <div class="col-md-4 mt-2">
                  <div class="form-group">
                    <label>Nama</label>
                    <input
                      type="text"
                      class="form-control"
                      :class="formValidate.full_name ? ' is-invalid' : ''"
                      name="full_name"
                      v-model="form.full_name"
                    />
                    <div
                      class="invalid-feedback"
                      v-html="formValidate.full_name"
                    ></div>
                  </div>
                </div>
                <div class="col-md-4 mt-2">
                  <div class="form-group">
                    <label>Telp/WA</label>
                    <input
                      type="text"
                      class="form-control"
                      :class="formValidate.phone_number ? ' is-invalid' : ''"
                      name="phone_number"
                      v-model="form.phone_number"
                    />
                    <div
                      class="invalid-feedback"
                      v-html="formValidate.phone_number"
                    ></div>
                  </div>
                </div>

                <div class="col-md-4 mt-2">
                  <div class="form-group">
                    <label>Username</label>
                    <input
                      type="text"
                      class="form-control"
                      :class="formValidate.username ? ' is-invalid' : ''"
                      name="username"
                      v-model="form.username"
                    />
                    <div
                      class="invalid-feedback"
                      v-html="formValidate.username"
                    ></div>
                  </div>
                </div>

                <div class="col-md-4 mt-2">
                  <div class="form-group">
                    <label>Password</label>
                    <input
                      type="password"
                      class="form-control"
                      :class="formValidate.password ? ' is-invalid' : ''"
                      name="password"
                      v-model="form.password"
                    />
                    <div
                      class="invalid-feedback"
                      v-html="formValidate.password"
                    ></div>
                  </div>
                </div>
                <div class="col-md-4 mt-2">
                  <div class="form-group">
                    <label>Ulangi Password</label>
                    <input
                      type="password"
                      class="form-control"
                      :class="
                        formValidate.confirm_password ? ' is-invalid' : ''
                      "
                      name="confirm_password"
                      v-model="form.confirm_password"
                    />
                    <div
                      class="invalid-feedback"
                      v-html="formValidate.confirm_password"
                    ></div>
                  </div>
                </div>
                <div class="col-md-4 mt-2">
                  <div class="form-group">
                    <label>Level User</label>
                    <select
                      name="level"
                      class="form-select"
                      :class="formValidate.level ? ' is-invalid' : ''"
                      v-model="form.level"
                    >
                      <option value="admin">Admin</option>
                    </select>
                    <div
                      class="invalid-feedback"
                      v-html="formValidate.level"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-footer">
              <button
                class="btn btn-sm btn-success float-end"
                @click="postData()"
                :disabled="this.loading"
              >
                Simpan
              </button>
            </div>
          </div>
        </form>
      </div>
    </main>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";

export default {
  name: "FormPage",
  data() {
    return {
      form: {
        full_name: "",
        phone_number: "",
        username: "",
        password: "",
        confirm_password: "",
        level: "",
      },
      formValidate: [],
      loading: false,
    };
  },
  methods: {
    postData() {
      this.loading = true;
      const formData = new FormData();
      formData.append("full_name", this.form.full_name);
      formData.append("username", this.form.username);
      formData.append("phone_number", this.form.phone_number);
      formData.append("password", this.form.password);
      formData.append("confirm_password", this.form.confirm_password);
      formData.append("level", this.form.level);

      axios
        .post("/v1/users/create/", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          // console.log(response.data.message);
          if (response.data.validasi == false) {
            this.loading = false;
            this.formValidate = response.data.message;
          } else {
            this.loading = false;
            // console.log(response.data.message);
            Swal.fire({
              title: response.data.title,
              text: response.data.message,
              icon: response.data.icon,
              showCancelButton: false,
              confirmButtonColor: "#3085d6",
              confirmButtonText: "OK!",
            }).then(() => {
              this.$router.push("/pengguna");
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
  },
};
</script>