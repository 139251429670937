<template>
  <div style="background-color: aliceblue">
    <Header></Header>
    <main>
      <div class="p-4">
        <div
          class="row align-items-center justify-content-center"
          style="margin-top: -180px"
        >
          <div class="col-md-6 bg-white p-4 rounded-5 shadow-lg box">
            <h4 class="text-center">Formulir Pembuatan KTM</h4>
            <hr />
            <div>
              <div class="row mb-3">
                <div class="col-md-4">
                  <label>Nama Lengkap</label>
                </div>
                <div class="col-md-8">
                  <input
                    v-model="form.nama_lengkap"
                    type="text"
                    placeholder="Nama Lengkap"
                    class="form-control"
                    @change="formValidate.nama_lengkap = ''"
                    :class="{
                      'is-valid': form.nama_lengkap != '',
                      'is-invalid':
                        formValidate.nama_lengkap || form.nama_lengkap == '',
                    }"
                  />
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-md-4">
                  <label>NIM</label>
                </div>
                <div class="col-md-8">
                  <input
                    v-model="form.nim"
                    type="number"
                    placeholder="NIM"
                    class="form-control"
                    @change="formValidate.nim = ''"
                    :class="{
                      'is-valid': form.nim != '',
                      'is-invalid': formValidate.nim || form.nim == '',
                    }"
                  />
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-md-4">
                  <label>No.HP/WA</label>
                </div>
                <div class="col-md-8">
                  <input
                    v-model="form.telp"
                    type="number"
                    placeholder="Nomor WA Aktif"
                    class="form-control"
                    @change="formValidate.telp = ''"
                    :class="{
                      'is-valid': form.telp != '',
                      'is-invalid': formValidate.telp || form.telp == '',
                    }"
                  />
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-md-4">
                  <label>Tempat Lahir</label>
                </div>
                <div class="col-md-8">
                  <input
                    v-model="form.tempat_lahir"
                    type="text"
                    class="form-control"
                    placeholder="Tempat Lahir"
                    @change="formValidate.tempat_lahir = ''"
                    :class="{
                      'is-valid': form.tempat_lahir != '',
                      'is-invalid':
                        formValidate.tempat_lahir || form.tempat_lahir == '',
                    }"
                  />
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-md-4">
                  <label>Tanggal Lahir</label>
                </div>
                <div class="col-md-8">
                  <div class="input-group mb-3">
                    <input
                      type="number"
                      v-model="form.tgl_lahir"
                      class="form-control text-center"
                      @change="formValidate.tanggal_lahir = ''"
                      :class="{
                        'is-valid': form.tgl_lahir != '',
                        'is-invalid':
                          formValidate.tanggal_lahir || form.tgl_lahir == '',
                      }"
                      placeholder="Tanggal"
                    />
                    <select
                      class="form-select text-center"
                      v-model="form.bln_lahir"
                      @change="formValidate.tanggal_lahir = ''"
                      :class="{
                        'is-valid': form.bln_lahir != '',
                        'is-invalid':
                          formValidate.tanggal_lahir || form.bln_lahir == '',
                      }"
                    >
                      <option value="">Bulan</option>
                      <option value="01">Januari</option>
                      <option value="02">Februari</option>
                      <option value="03">Maret</option>
                      <option value="04">April</option>
                      <option value="05">Mei</option>
                      <option value="06">Juni</option>
                      <option value="07">Juli</option>
                      <option value="08">Agustus</option>
                      <option value="09">September</option>
                      <option value="10">Oktober</option>
                      <option value="11">November</option>
                      <option value="12">Desember</option>
                    </select>
                    <input
                      type="number"
                      v-model="form.thn_lahir"
                      class="form-control text-center"
                      @change="formValidate.tanggal_lahir = ''"
                      :class="{
                        'is-valid': form.thn_lahir != '',
                        'is-invalid':
                          formValidate.tanggal_lahir || form.thn_lahir == '',
                      }"
                      placeholder="Tahun"
                    />
                  </div>
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-md-4">
                  <label>Progam Studi</label>
                </div>
                <div class="col-md-8">
                  <select
                    class="form-select"
                    v-model="form.prodi"
                    @change="formValidate.prodi = ''"
                    :class="{
                      'is-valid': form.prodi != '',
                      'is-invalid': formValidate.prodi || form.prodi == '',
                    }"
                  >
                    <option value="">Pilih Program Studi</option>
                    <option value="Sistem Informasi">Sistem Informasi</option>
                    <option value="Teknik Informatika">
                      Teknik Informatika
                    </option>
                  </select>
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-md-4">
                  <label>Angkatan</label>
                </div>
                <div class="col-md-8">
                  <input
                    v-model="form.angkatan"
                    type="number"
                    class="form-control"
                    placeholder="Tahun Angkatan, Contoh 2023"
                    @change="formValidate.angkatan = ''"
                    :class="{
                      'is-valid': form.angkatan != '',
                      'is-invalid':
                        formValidate.angkatan || form.angkatan == '',
                    }"
                  />
                </div>
              </div>
              <div
                class="row mb-3"
                v-if="form.nim && form.prodi && form.nama_lengkap"
              >
                <div class="col-md-4">
                  <label>Foto</label>
                </div>
                <div class="col-md-8">
                  <a
                    href="#"
                    class="btn btn-block btn-primary m-1"
                    :class="{
                      'btn-danger': formValidate.ktm,
                    }"
                    role="button"
                    @click.prevent="showFileChooser"
                  >
                    <i class="fa fa-fw fa-image"></i> Pilih/Ambil Foto
                  </a>
                </div>
              </div>
              <input
                style="display: none"
                ref="input"
                type="file"
                name="image"
                accept="image/*"
                @change="setImage"
              />
              <div class="row mb-3">
                <div class="col-md-4"></div>
                <div class="col-md-4 col-10">
                  <div class="img-cropper">
                    <vue-cropper
                      ref="cropper"
                      :aspect-ratio="3 / 4"
                      :src="imgSrc"
                      preview=".preview"
                      style="width: 100%; border-radius: 5px; overflow: hidden"
                    />
                    <div v-if="this.imgSrc">
                      <div class="btn-groups mt-1 mb-3">
                        <a
                          href="#"
                          class="btn btn-sm btn-info"
                          style="margin-right: 5px"
                          role="button"
                          @click.prevent="rotate(90)"
                        >
                          <font-awesome-icon icon="redo" />
                        </a>
                        <a
                          href="#"
                          class="btn btn-sm btn-info"
                          style="margin-right: 5px"
                          role="button"
                          @click.prevent="rotate(-90)"
                        >
                          <font-awesome-icon icon="undo" />
                        </a>
                        <a
                          href="#"
                          class="btn accordion-buttonbtn-sm btn-sm btn-primary"
                          role="button"
                          @click.prevent="cropImage"
                        >
                          <font-awesome-icon icon="crop" /> Crop Foto
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-4 col-12">
                  <canvas ref="myCanvas" style="display: none"></canvas>
                  <img
                    v-if="cropImg"
                    ref="canvasImage"
                    width="100%"
                    style="border-radius: 10px"
                  />
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-md-4"></div>
              </div>
              <div class="col-md-12" v-if="this.loadingUpload">
                <div class="progress">
                  <div
                    id="progressBar"
                    class="progress-bar"
                    role="progressbar"
                    aria-valuenow="0"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
              </div>
              <hr />
              <div class="row">
                <div class="col-md-12">
                  <a
                    href="javascript:window.history.go(-1);"
                    class="btn btn-secondary"
                  >
                    Kembali
                  </a>
                  <button
                    v-if="
                      fotoKTM && form.nim && form.prodi && form.nama_lengkap
                    "
                    @click="submitForm()"
                    class="btn btn-primary float-end"
                    type="button"
                    :disabled="this.loading"
                  >
                    <font-awesome-icon
                      v-if="loading"
                      icon="circle-notch"
                      :spin="loading"
                    />
                    <span v-if="this.loadingUpload"> Upload Foto</span>
                    <span v-if="this.loadingSubmit"> Mengirim Data</span>
                    <span v-if="!this.loading"> Kirim</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "./Header.vue";
import Footer from "./Footer.vue";
import Swal from "sweetalert2";
import axios from "axios";
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";

export default {
  name: "HomePage",
  components: {
    Header,
    Footer,
    VueCropper,
  },
  data() {
    return {
      form: {
        nama_lengkap: "",
        nim: "",
        telp: "",
        prodi: "",
        tempat_lahir: "",
        tgl_lahir: "",
        bln_lahir: "",
        thn_lahir: "",
        angkatan: "",
      },
      formValidate: [],
      loading: false,
      loadingUpload: false,
      loadingSubmit: false,
      imgSrc: "",
      cropImg: "",
      fotoMhs: "",
      fotoKTM: "",
    };
  },
  // mounted() {
  //   this.drawCanvas();
  // },
  methods: {
    submitForm() {
      this.loading = true;

      // Memanggil metode untuk mengunggah foto
      this.uploadPhotos()
        .then(() => {
          this.loadingSubmit = true;
          let tanggLahir =
            this.form.thn_lahir +
            "-" +
            this.form.bln_lahir +
            "-" +
            this.form.tgl_lahir;
          const formData = new FormData();
          for (let key in this.form) {
            formData.append(key, this.form[key]);
          }
          formData.append("tanggal_lahir", tanggLahir);

          // Melanjutkan untuk menyimpan data setelah unggah foto berhasil
          return axios.post("/v1/permohonan_ktm/create/", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });
        })
        .then((response) => {
          if (response.data.validasi == false) {
            this.loading = false;
            this.formValidate = response.data.message;
          } else {
            this.loading = false;
            this.loadingSubmit = false;
            Swal.fire({
              title: "Sukses",
              text: "Data Berhasil Dikirim",
              icon: "success",
              showCancelButton: false,
              showConfirmButton: false,
            });
            setTimeout(() => {
              Swal.close();
              this.$router.go(-1);
            }, 700);
          }
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    uploadPhotos() {
      this.loadingUpload = true;
      let progressPercentage = 0;
      const config = {
        onUploadProgress: function (progressEvent) {
          progressPercentage = parseInt(
            Math.round((progressEvent.loaded * 100) / progressEvent.total)
          );
          document.getElementById("progressBar").style.width =
            progressPercentage + "%";
        },
      };

      // Membuat FormData untuk mengunggah foto
      let tanggLahir =
        this.form.thn_lahir +
        "-" +
        this.form.bln_lahir +
        "-" +
        this.form.tgl_lahir;
      const formData = new FormData();
      for (let key in this.form) {
        formData.append(key, this.form[key]);
      }
      formData.append("tanggal_lahir", tanggLahir);
      formData.append("img", this.fotoMhs);
      formData.append("ktm", this.fotoKTM);

      // Mengunggah foto menggunakan axios
      return axios
        .post("/v1/permohonan_ktm/upload/", formData, config)
        .then((response) => {
          // Mengecek apakah validasi berhasil atau gagal
          if (response.data.validasi === false) {
            // Jika validasi gagal, hentikan loading dan tampilkan pesan validasi
            this.loading = false;
            this.formValidate = response.data.message;
          } else {
            this.loadingUpload = false;
            // Jika validasi berhasil, lanjutkan dengan tindakan selanjutnya
            // Misalnya, lanjutkan dengan menyimpan data atau melakukan tindakan lainnya
            // return response untuk memberi tahu bahwa proses telah selesai
            return response;
          }
        })
        .catch((error) => {
          // Tangani kesalahan jika terjadi
          console.log(error);
          this.error = error.message;
          throw error; // lempar error agar bisa ditangani di tempat lain jika perlu
        });
    },

    dataURLtoBlob(dataURL) {
      const base64Data = dataURL.split(",")[1];
      const byteCharacters = atob(base64Data);
      const byteNumbers = new Array(byteCharacters.length);

      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      return new Blob([byteArray], { type: "image/png" });
    },

    cropImage() {
      // get image data for post processing, e.g. upload or setting image src
      this.cropImg = this.$refs.cropper.getCroppedCanvas().toDataURL();
      this.fotoMhs = this.$refs.cropper.getCroppedCanvas().toDataURL();
      this.drawCanvas();
    },
    rotate(deg) {
      this.$refs.cropper.rotate(deg);
    },

    setImage(e) {
      const file = e.target.files[0];

      if (file.type.indexOf("image/") === -1) {
        alert("Please select an image file");
        return;
      }

      if (typeof FileReader === "function") {
        const reader = new FileReader();

        reader.onload = (event) => {
          this.imgSrc = event.target.result;
          // rebuild cropperjs with the updated source
          this.$refs.cropper.replace(event.target.result);
        };

        reader.readAsDataURL(file);
      } else {
        alert("Sorry, FileReader API not supported");
      }
    },
    showFileChooser() {
      this.$refs.input.click();
    },
    drawCanvas() {
      const canvas = this.$refs.myCanvas;
      const context = canvas.getContext("2d");
      const dpi = window.devicePixelRatio || 1;

      // Mengatur lebar dan tinggi canvas sesuai dengan dimensi fisik
      canvas.width = 2528 * dpi; // Lebar dalam piksel
      canvas.height = 1598 * dpi; // Tinggi dalam piksel

      context.imageSmoothingQuality = "high";

      // Gambar latar belakang
      context.fillStyle = "#f0f0f0";
      context.fillRect(0, 0, canvas.width, canvas.height);

      const blanko = new Image();
      if (this.form.prodi == "Sistem Informasi") {
        blanko.src = require("@/assets/blanko-si.png");
      } else {
        blanko.src = require("@/assets/blanko-ti.png");
      }

      blanko.onload = () => {
        context.drawImage(blanko, 0, 0, canvas.width, canvas.height);

        const sairaFont = new FontFace(
          "Saira",
          `url(${require("@/assets/saira.ttf")})`
        );
        sairaFont.load().then((font) => {
          document.fonts.add(font);
          const foto = new Image();
          foto.src = this.cropImg;

          foto.onload = () => {
            // Gambar latar belakang dengan lengkungan di setiap sudut
            this.drawRoundedRect(
              context,
              150 * dpi,
              570 * dpi,
              500 * dpi,
              650 * dpi,
              50 * dpi
            );

            // Hentikan penggunaan clip agar tidak membatasi konteks gambar di luar foto.onload
            context.clip("nonzero");

            // Menggambar gambar ke dalam canvas
            context.drawImage(foto, 150 * dpi, 570 * dpi, 500 * dpi, 650 * dpi);

            const canvasImage = this.$refs.canvasImage;

            const dataURL = canvas.toDataURL("image/png");

            canvasImage.src = dataURL;
            this.fotoKTM = dataURL;
          };

          context.font = `bold ${140 * dpi}px Saira`;
          context.fillStyle = "#274665";
          context.fillText(
            this.form.nama_lengkap.toUpperCase(),
            755 * dpi,
            850 * dpi
          );

          context.font = `bold ${120 * dpi}px Saira`;
          context.fillStyle = "#274665";
          context.fillText(this.form.nim, 755 * dpi, 1010 * dpi);
        });
      };
    },

    drawRoundedRect(context, x, y, width, height, radius) {
      context.beginPath();
      context.moveTo(x + radius, y);
      context.arcTo(x + width, y, x + width, y + height, radius);
      context.arcTo(x + width, y + height, x, y + height, radius);
      context.arcTo(x, y + height, x, y, radius);
      context.arcTo(x, y, x + width, y, radius);
      context.closePath();
    },
  },
};
</script>

<style scoped>
@font-face {
  font-family: "Saira";
  src: url("@/assets/saira.ttf") format("truetype");
}
.no-link {
  text-decoration: none;
}
.box {
  border-top: solid 5px #2968a0;
  border-bottom: solid 5px #0099ff;
}
.skeleton-loader {
  width: 100%;
  height: 20px;
  display: block;
  border-radius: 3px;
  background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0.5) 50%,
      rgba(255, 255, 255, 0) 80%
    ),
    #d3d3d3;
  background-repeat: repeat-y;
  background-size: 50px 500px;
  background-position: 0 0;
  animation: shine 1s infinite;
}
@keyframes shine {
  to {
    background-position: 100% 0, /* move highlight to right */ 0 0;
  }
}

.image-groups {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
  border: 1px solid #ccc;
  margin-bottom: 5px;
}

/* Image styling */
.image-container {
  display: flex;
  align-items: center;
  width: 30px;
  height: 30px;
}

.image-item {
  min-width: 30px;
  max-width: 30px;
  min-height: 30px;
  max-height: 30px;
  object-fit: cover;
  margin-right: 5px;
}

.tombol-hapus {
  float: right;
  color: rgb(255, 0, 0);
  border-radius: 3px;
  border: none;
  height: 30px;
  width: 30px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8);
}
</style>
